<script setup>
import { ElDivider, ElForm, ElFormItem } from "element-plus";
import { reactive, ref } from "vue";

import * as APIs from "@/APIs";

const emit = defineEmits(["success"]);

const formRef = ref();
const formData = ref({
  file: null,
});
const errorMessages = ref([]);

const formRules = reactive({
  file: { required: true, message: "請上傳檔案" },
});

/**
 * @param {any[]} arr
 * @param {Function} iteratee
 * @returns {any[][]} - Grouped array
 */
const groupBy = (arr, iteratee) =>
  Object.entries(
    arr.reduce((acc, item) => {
      const key =
        typeof iteratee === "function" ? iteratee(item) : item[iteratee];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {}),
  );

const submit = async () => {
  return new Promise((resolve, reject) => {
    formRef.value.validate(async (valid) => {
      if (!valid) {
        reject(new Error("請選擇檔案"));
        return;
      }
      APIs.task
        .importTasks(formData.value.file)
        .then((res) => {
          emit("success");
          resolve(res);
        })
        .catch((error) => {
          const message = error.response.data.message;
          let parsedMessage;
          try {
            parsedMessage = JSON.parse(message);
          } catch (error) {
            parsedMessage = message;
          }
          errorMessages.value = Array.isArray(parsedMessage)
            ? groupBy(parsedMessage, (item) => item.row)
            : parsedMessage;
          reject(error);
        });
    });
  });
};

defineExpose({
  submit,
});
</script>

<template>
  <ElForm
    ref="formRef"
    :model="formData"
    :rules="formRules"
    label-position="top"
  >
    <ElFormItem prop="file" label="上傳檔案">
      <label class="block">
        <input
          type="file"
          accept=".zip"
          class="block w-full text-sm text-slate-500 file:mr-4 file:!rounded-full file:border-0 file:bg-violet-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-violet-700 hover:file:bg-violet-100 file:disabled:cursor-not-allowed file:disabled:bg-gray-100 file:disabled:text-gray-400"
          @change="
            (e) => {
              errorMessages = [];
              formData.file = e.target.files[0];
            }
          "
        />
      </label>
    </ElFormItem>
    <ElFormItem>
      <ul class="list-disc pl-5 leading-normal">
        <li class="marker:content-['*_']">支持檔案格式：.zip</li>
        <li class="marker:content-['*_']">
          圖檔和.CSV格式文檔需打包後一起上傳，圖檔和文檔的圖片名稱需相同
        </li>
        <li class="marker:content-['*_']">
          .CSV文檔請依照「範例說明」格式填寫，請勿任意刪改檔案欄位
        </li>
        <li class="marker:content-['*_']">
          上傳後檔案無法變更，請使用任務列表中的操作按鈕「編輯」單題修改
        </li>
      </ul>
    </ElFormItem>
    <template v-if="errorMessages.length">
      <ElDivider>
        <div class="flex items-center gap-1 text-red-500">
          <span class="material-symbols-outlined">error</span>
          <span>錯誤</span>
        </div>
      </ElDivider>
      <table v-if="Array.isArray(errorMessages)" class="w-full">
        <thead>
          <tr>
            <th class="border px-2 py-1"></th>
            <th class="border px-2 py-1">欄位</th>
            <th class="border px-2 py-1">錯誤訊息</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="([row, groupedMessages], i) in errorMessages"
            :key="row"
          >
            <tr
              v-for="(message, j) in groupedMessages"
              :key="`${row}-${j}`"
              :class="{
                'bg-slate-100': i % 2 === 0,
              }"
            >
              <td
                v-if="j === 0"
                class="border px-2 py-1"
                :rowspan="groupedMessages.length"
              >
                第 {{ row }} 列
              </td>
              <td class="border px-2 py-1">{{ message.key }}</td>
              <td class="border px-2 py-1">{{ message.error }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <template v-else>
        <p class="whitespace-nowrap">{{ errorMessages }}</p>
      </template>
    </template>
  </ElForm>
</template>

<style lang="scss" scoped></style>
