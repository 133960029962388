<script setup>
import { ElButton, ElCard, ElDialog, ElNotification } from "element-plus";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import * as APIs from "@/APIs";
import Breadcrumb from "@/components/Breadcrumb.vue";
import TaskableForm from "@/components/Task/TaskableForm.vue";
import TaskablePreview from "@/components/Task/TaskablePreview.vue";
import * as enums from "@/enums";

const props = defineProps({
  taskableType: String,
  id: String,
});

const router = useRouter();

const taskableFormRef = ref();

const loading = ref(false);
const dialogVisible = ref(false);
const createMode = computed(() => props.id === enums.common.CREATE_MODE);

const formData = ref({});

const handlePreview = () => {
  taskableFormRef.value?.validate().then((data) => {
    formData.value = data;
    dialogVisible.value = true;
  });
};

const handleSubmit = async () => {
  try {
    loading.value = true;
    if (createMode.value)
      await APIs.taskable
        .createTaskable(formData.value.taskable_type, formData.value)
        .then((res) => {
          router.replace({
            params: {
              taskableType: formData.value.taskable_type,
              id: res.result.id,
            },
          });
        });
    else
      await APIs.taskable.updateTaskable(
        formData.value.taskable_type,
        props.id,
        formData.value,
      );
    dialogVisible.value = false;
    ElNotification({
      title: `${createMode.value ? "新增" : "更新"}成功`,
      type: "success",
    });
  } finally {
    loading.value = false;
  }
};

if (!createMode.value) {
  loading.value = true;
  APIs.taskable
    .getTaskable(props.taskableType, props.id)
    .then((res) => {
      taskableFormRef.value?.initFormData(res.result);
      formData.value.title = res.result.title;
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>

<template>
  <div>
    <Breadcrumb
      :breadcrumbs="[
        {
          label: `${$route.meta.title}列表`,
          to: { name: enums.route.names.TASKABLES },
        },
        { label: createMode ? `${$route.meta.title}` : formData.title },
      ]"
    />
    <ElCard v-loading="loading" class="!rounded-xl">
      <div class="max-w-screen-lg">
        <h1 class="mb-3 text-2xl font-bold">
          {{ createMode ? "新增" : $route.meta.title }}
        </h1>
        <TaskableForm
          ref="taskableFormRef"
          :taskable-type-changeable="createMode"
        />
        <div class="flex w-full items-center justify-center gap-3 pt-3">
          <RouterLink :to="{ name: enums.route.names.TASKABLES }">
            <ElButton type="info" plain>返回</ElButton>
          </RouterLink>
          <ElButton type="primary" @click="handlePreview">預覽</ElButton>
        </div>
      </div>
    </ElCard>
    <ElDialog v-model="dialogVisible" width="90%" class="max-w-screen-md">
      <template #header>
        <h3 class="text-xl font-bold">預覽</h3>
      </template>
      <TaskablePreview :data="formData" />
      <template #footer>
        <ElButton
          type="info"
          :disabled="loading"
          @click="dialogVisible = false"
        >
          返回修改
        </ElButton>
        <ElButton type="primary" :loading="loading" @click="handleSubmit">
          確認儲存
        </ElButton>
      </template>
    </ElDialog>
  </div>
</template>

<style lang="scss" scoped></style>
