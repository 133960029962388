<script setup>
import {
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElNotification,
  ElOption,
  ElSelect,
  ElSwitch,
  ElTag,
} from "element-plus";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import ColorBlockEditor from "@/components/ColorBlockEditor.vue";
import ElImageUpload from "@/components/ElImageUpload.vue";
import * as constants from "@/constants";
import * as enums from "@/enums";
import * as helpers from "@/libs/helpers";
import * as utils from "@/libs/utils";
import * as TaskableModel from "@/models/taskable";

defineProps({
  taskableTypeChangeable: {
    type: Boolean,
    default: true,
  },
  availableTaskableTypes: {
    type: Array,
    default: () => Object.values(enums.taskable.taskableTypes),
  },
});

const route = useRoute();

const formData = ref(
  TaskableModel.getEmptyData(
    route.params.taskableType || enums.taskable.taskableTypes.SURVEY,
  ),
);

const formRef = ref();

const formRules = computed(() => ({
  title: { required: true, message: "請輸入題目" },
  description: { required: true, message: "請輸入題目說明" },
  taskable_type: { required: true, message: "請選擇題型" },
  url: [
    {
      required:
        formData.value.taskable_type === enums.taskable.taskableTypes.CLICK,
      message: "請輸入連結位置",
    },
    { type: "url", message: "連結格式錯誤" },
  ],
  video_url: {
    required:
      formData.value.taskable_type === enums.taskable.taskableTypes.VIDEO,
    message: "請輸入影片連結",
  },
  video_length: {
    required:
      formData.value.taskable_type === enums.taskable.taskableTypes.VIDEO,
    message: "請輸入影片長度",
  },
  video_id1: {
    required:
      formData.value.taskable_type === enums.taskable.taskableTypes.VIDEO,
    message: "請輸入影片ID1",
  },
  video_id2: {
    required:
      formData.value.taskable_type === enums.taskable.taskableTypes.VIDEO,
    message: "請輸入影片ID2",
  },
}));

const isColorBlock = ref(false);

const validate = () => {
  return new Promise((resolve, reject) => {
    formRef.value.validate((valid, rules) => {
      if (valid) {
        resolve(
          helpers.dataConverter(formData.value, {
            options: (options) => options?.filter?.((item) => item.content),
          }),
        );
      } else {
        ElNotification({
          title: "表單驗證錯誤",
          type: "error",
          message: Object.values(rules)[0][0].message,
        });
        reject(rules);
      }
    });
  });
};

/**
 * @param {import("@/models/taskable").Taskable>} payload
 */
const initFormData = (payload) => {
  helpers.assign(formData.value, TaskableModel.factory(payload).value());

  // 判斷是否使用顏色區塊
  isColorBlock.value = formData.value.options
    ?.filter((item) => item.image)
    .every((item) =>
      utils.containsDomain(item.image, import.meta.env.VITE_PLACEHOLDER_IMAGE),
    );
};

defineExpose({
  validate,
  initFormData,
});
</script>

<template>
  <ElForm ref="formRef" :model="formData" :rules="formRules" label-width="90px">
    <ElFormItem prop="taskable_type" label="題型">
      <ElSelect
        v-if="taskableTypeChangeable"
        v-model="formData.taskable_type"
        placeholder="請選擇題型"
        clearable
      >
        <ElOption
          v-for="(label, value) in constants.taskable.taskableTypeLabels"
          :key="value"
          :label="label"
          :value="value"
          :disabled="
            !availableTaskableTypes.includes(value) &&
            value !== formData.taskable_type
          "
        />
      </ElSelect>
      <ElTag v-else class="!text-base font-bold" size="large">
        {{ constants.taskable.taskableTypeLabels[formData.taskable_type] }}
      </ElTag>
    </ElFormItem>
    <template
      v-if="
        [
          enums.taskable.taskableTypes.QUESTION,
          enums.taskable.taskableTypes.SURVEY,
          enums.taskable.taskableTypes.VIDEO,
        ].includes(formData.taskable_type)
      "
    >
      <ElFormItem prop="title" label="題目標題">
        <ElInput v-model="formData.title" />
      </ElFormItem>
      <ElFormItem prop="description" label="題目說明">
        <ElInput
          v-model="formData.description"
          type="textarea"
          :autosize="{ minRows: 3 }"
        />
      </ElFormItem>
    </template>
    <template
      v-if="formData.taskable_type === enums.taskable.taskableTypes.CLICK"
    >
      <ElFormItem prop="url" label="導連">
        <ElInput v-model="formData.url" />
      </ElFormItem>
    </template>
    <template
      v-else-if="formData.taskable_type === enums.taskable.taskableTypes.VIDEO"
    >
      <ElFormItem prop="video_url" label="影片連結">
        <ElInput v-model="formData.video_url" />
      </ElFormItem>
      <ElFormItem prop="video_length" label="影片長度">
        <ElInput v-model="formData.video_length" />
      </ElFormItem>
      <ElFormItem label="影片ID" required>
        <div class="flex gap-2">
          <ElFormItem prop="video_id1">
            <ElInput v-model="formData.video_id1" placeholder="ID1" />
          </ElFormItem>
          <ElFormItem prop="video_id2">
            <ElInput v-model="formData.video_id2" placeholder="ID2" />
          </ElFormItem>
          <button type="button" class="self-end text-xs text-primary underline">
            獲取方式參照
          </button>
        </div>
      </ElFormItem>
    </template>
    <template
      v-else-if="
        [
          enums.taskable.taskableTypes.QUESTION,
          enums.taskable.taskableTypes.SURVEY,
        ].includes(formData.taskable_type)
      "
    >
      <ElFormItem>
        <div class="flex gap-1">
          <span>最少可選</span>
          <ElInputNumber
            v-model="formData.min_choices"
            :controls="false"
            :min="0"
            :max="formData.max_choices"
            step-strictly
            size="small"
          />
          <span>題</span>
        </div>
      </ElFormItem>
      <ElFormItem>
        <div class="flex gap-1">
          <span>最多可選</span>
          <ElInputNumber
            v-model="formData.max_choices"
            :controls="false"
            :min="formData.min_choices"
            :max="formData.options.length"
            step-strictly
            size="small"
          />
          <span>題</span>
        </div>
      </ElFormItem>
      <ElFormItem label="顏色區塊">
        <ElSwitch
          v-model="isColorBlock"
          active-text="使用"
          inactive-text="不使用"
          @change="
            (value) => {
              if (!value) {
                formData.options.forEach((item) => {
                  item.image = '';
                });
              }
            }
          "
        />
      </ElFormItem>
      <ElFormItem
        v-for="(item, i) in formData.options"
        :key="i"
        :label="`題目${i + 1}`"
      >
        <div class="flex flex-wrap items-end gap-3">
          <template v-if="isColorBlock">
            <ColorBlockEditor v-model:src="item.image" :text="item.content" />
          </template>
          <template v-else>
            <ElImageUpload v-model="item.image">
              <template #trigger>
                <div
                  class="grid h-32 w-32 place-items-center text-xl text-primary"
                >
                  ＋
                </div>
              </template>
              <template #preview="{ src }">
                <div ratio="100%" class="aspect-square w-32">
                  <img :src="src" class="h-full w-full object-cover" />
                </div>
              </template>
            </ElImageUpload>
          </template>
          <ElCheckbox
            v-if="
              formData.taskable_type === enums.taskable.taskableTypes.QUESTION
            "
            v-model="item.is_correct"
            label="正確答案"
          />
          <ElFormItem :prop="`options[${i}].content`" class="w-full">
            <ElInput
              v-model="item.content"
              placeholder="請輸入選項內容"
              :type="isColorBlock ? 'textarea' : 'text'"
              :autosize="{ minRows: 2 }"
            />
          </ElFormItem>
        </div>
      </ElFormItem>
    </template>
  </ElForm>
</template>

<style lang="scss" scoped></style>
