import { defineStore } from "pinia";
import { ref } from "vue";

import * as APIs from "@/APIs";
import * as enums from "@/enums";
import EventEmitter from "@/libs/event-emitter";
import * as helpers from "@/libs/helpers";
import * as TaskModel from "@/models/task";

export const useTaskStore = defineStore("task", () => {
  const task = ref(TaskModel.getEmptyData());
  const brands = ref([]);

  const reset = (initData) => {
    task.value = TaskModel.getEmptyData();
    if (initData) helpers.assign(task.value, initData);
    brands.value = [];
  };

  const fetchData = (id) => {
    EventEmitter.emit(enums.emitter.events.TASK_FETCH_START, id);
    return APIs.task
      .getTask(id, {
        relationships: [
          "brands",
          "taskables",
          "exp",
          "coin",
          "videos",
          "clicks",
          "questions",
          "surveys",
        ],
      })
      .then((result) => {
        if (result.brands?.length) brands.value = result.brands;
        task.value = TaskModel.taskFactory(result).value();
        EventEmitter.emit(enums.emitter.events.TASK_FETCH_SUCCESS, result);
        return result;
      });
  };

  return { task, brands, fetchData, reset };
});
