<script setup>
import {
  ElButton,
  ElCard,
  ElForm,
  ElFormItem,
  ElInput,
  ElPopover,
  ElTableColumn,
  ElTooltip,
} from "element-plus";
import numeral from "numeral";
import { computed, ref } from "vue";

import * as APIs from "@/APIs";
import ElPaginationTable from "@/components/ElPaginationTable.vue";
import ElStatusSwitchPrompt from "@/components/ElStatusSwitchPrompt.vue";
import * as helpers from "@/libs/helpers";

const loading = ref(false);
const list = ref([]);
const paginationParams = ref({
  page: 1,
  per_page: 10,
  total: 0,
});
const sortParams = ref({
  prop: "created_at",
  order: "desc",
});
const searchParams = ref({
  name: "",
});
const params = computed(() => ({
  page: paginationParams.value.page,
  per_page: paginationParams.value.per_page,
  order_by: sortParams.value.prop,
  order_direction: sortParams.value.order,
}));

/**
 * @param {typeof params.value} query
 */
const handleFetch = (query) => {
  loading.value = true;
  APIs.subspecies
    .getSubspeciesList({
      page: query.page,
      per_page: query.per_page,
      filters: helpers.convertSearchParams(searchParams.value),
      relationships: ["specy"],
      order_by: query.order_by,
      order_direction: query.order_direction?.match(/asc|desc/)?.[0],
    })
    .then((res) => {
      paginationParams.value.total = res.result.total;
      list.value = res.result.data;
    })
    .finally(() => {
      loading.value = false;
    });
};

handleFetch(params.value);
</script>

<template>
  <ElCard class="!rounded-xl">
    <h1 class="mb-3 text-2xl font-bold">{{ $route.meta.title }}</h1>
    <ElForm inline label-width="80">
      <ElFormItem label="名稱:">
        <ElInput
          v-model="searchParams.name"
          :formatter="(val) => val.replaceAll('%', '')"
          :parser="(val) => `%${val.replaceAll('%', '')}%`"
        />
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" @click="handleFetch(params)">搜尋</ElButton>
      </ElFormItem>
    </ElForm>
    <ElPaginationTable
      :data="list"
      v-model:pagination="paginationParams"
      v-model:sort="sortParams"
      :loading="loading"
      paginationBackground
      @page-change="(page) => handleFetch({ ...params, page })"
      @page-size-change="(size) => handleFetch({ ...params, per_page: size })"
      @sort-change="
        (sort) =>
          handleFetch({
            ...params,
            order_by: sort.prop,
            order_direction: sort.order,
          })
      "
    >
      <ElTableColumn prop="image" label="妖獸圖" width="100">
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElTooltip placement="top" content="鼠標移至圖示上可查看大圖">
              <button class="material-symbols-outlined">info</button>
            </ElTooltip>
          </div>
        </template>
        <template #default="{ row }">
          <ElPopover placement="right" width="fit-content">
            <template #reference>
              <img :src="row.image" class="aspect-square w-10" />
            </template>
            <img :src="row.image" class="w-40" />
          </ElPopover>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="name" label="名稱" min-width="150" />
      <ElTableColumn prop="specy.name" label="種族" min-width="100" />
      <ElTableColumn
        prop="hatching_exp"
        label="培育值"
        min-width="100"
        :formatter="(row) => numeral(row.hatching_exp).format()"
      >
        <template #header="{ column }">
          <div class="flex items-center gap-1">
            <span>{{ column.label }}</span>
            <ElTooltip placement="top-end" content="孵化所需的培育值">
              <button class="material-symbols-outlined">info</button>
            </ElTooltip>
          </div>
        </template>
      </ElTableColumn>
      <ElTableColumn label="狀態" width="110" fixed="right">
        <template #default="{ row }">
          <ElStatusSwitchPrompt
            v-model="row.status"
            :prompt-options="{
              targetName: row.name,
            }"
            :prompt-async-callback="
              (status) => APIs.subspecies.updateSubspecies(row.id, { status })
            "
          />
        </template>
      </ElTableColumn>
    </ElPaginationTable>
  </ElCard>
</template>
