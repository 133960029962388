import "@/assets/main.scss";

import { createPinia } from "pinia";
import { createApp } from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

import App from "@/App.vue";
import facebookSDKPlugin from "@/plugins/facebook-sdk";
// import sentryPlugin from "@/plugins/sentry";
import router from "@/router";

const app = createApp(App);

app.use(facebookSDKPlugin, {
  appId: "1512482099598831",
  version: "v18.0",
  autoLogAppEvents: true,
  xfbml: true,
});
// app.use(sentryPlugin, {
//   router,
//   enabled: import.meta.env.MODE === "production",
// });
app.use(createPinia());
app.use(router);
app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_RECAPCHA_SITE_KEY });

app.mount("#app");
