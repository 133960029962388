import * as enums from "@/enums";
import * as helpers from "@/libs/helpers";

/**
 * Represents a option object.
 * @typedef {Object} Option
 * @property {string} id - The ID of the option.
 * @property {string} content - The content of the option.
 * @property {string} image - The image of the option.
 * @property {string} [taskable_id] - The taskable ID of the option.
 * @property {number|null} order - The order of the option.
 * @property {boolean} is_correct - The is_correct of the option.
 */

/**
 * Represents a taskable object.
 * @typedef {Object} Taskable
 * @property {string} id - The ID of the taskable.
 * @property {string} title - The title of the taskable.
 * @property {string} description - The description text.
 * @property {enums["taskable"]["taskableTypes"][keyof typeof enums["taskable"]["taskableTypes"]]} taskable_type - The type of the taskable.
 * @property {number|null} order - The order of the taskable.
 * @property {string} [url] - The URL of the taskable.
 * @property {string} [video_url] - The video URL of the taskable.
 * @property {number} [video_length] - The video length of the taskable.
 * @property {string} [video_id1] - The video ID1 of the taskable.
 * @property {string} [video_id2] - The video ID2 of the taskable.
 * @property {number} [min_choices] - The minimum number of choices allowed.
 * @property {number} [max_choices] - The maximum number of choices allowed.
 * @property {Array<Option>} [options] - The options for the taskable.
 */

export const keysMap = {
  [enums.taskable.taskableTypes.CLICK]: ["url"],
  [enums.taskable.taskableTypes.VIDEO]: [
    "title",
    "description",
    "video_url",
    "video_length",
    "video_id1",
    "video_id2",
  ],
  [enums.taskable.taskableTypes.QUESTION]: [
    "title",
    "description",
    "min_choices",
    "max_choices",
    "options",
  ],
  [enums.taskable.taskableTypes.SURVEY]: [
    "title",
    "description",
    "min_choices",
    "max_choices",
    "options",
  ],
};

/**
 * Converts the data for a taskable.
 * @param {enums["taskable"]["taskableTypes"][keyof typeof enums["taskable"]["taskableTypes"]]} taskableType - The type of the taskable.
 */
export const converter = (taskableType) => {
  return {
    toServer: function (data) {
      data = helpers.pick(data, ["order"].concat(keysMap[taskableType]));

      if (data.options) {
        data[`${taskableType}_options`] = data.options.filter(
          (item) => item.content,
        );
        delete data.options;
      }

      if (data.description) {
        data.question = data.description;
        delete data.description;
      }

      return data;
    },
    fromServer: function (data) {
      return {
        ...helpers.omit(data, ["taskable", `${taskableType}_options`]),
        description: data.question,
        taskable_type: taskableType,
        options: data[`${taskableType}_options`] ?? [],
      };
    },
  };
};

/**
 * Gets the empty data for a taskable.
 * @param {enums["taskable"]["taskableTypes"][keyof typeof enums["taskable"]["taskableTypes"]]} taskableType - The type of the taskable.
 * @returns {Taskable} The empty data for the taskable.
 * @example
 * const data = getEmptyData(enums.taskable.taskableTypes.CLICK);
 * data.update({ url: "https://www.google.com" });
 * console.log(data.value(["url"]));
 */
export const getEmptyData = (taskableType) => ({
  // id: "",
  title: "",
  description: "",
  order: 0,
  taskable_type: taskableType,
  ...helpers.pick(
    {
      url: "",
      video_url: "",
      video_length: "",
      video_id1: "",
      video_id2: "",
      min_choices: 1,
      max_choices: 1,
      options: new Array(4).fill({}).map((__, i) => ({
        id: helpers.getUniqueId(),
        content: "",
        image: "",
        order: i + 1,
        is_correct: false,
      })),
    },
    keysMap[taskableType],
  ),
});

/**
 * Creates a taskable object.
 * @param {enums["taskable"]["taskableTypes"][keyof typeof enums["taskable"]["taskableTypes"]]} taskableType - The type of the taskable.
 * @param {Taskable} data - The initial data for the taskable.
 */
export const factory = (data) => {
  /**
   * @type {Taskable}
   */
  const _this = {};

  /**
   * Sets the data for the taskable.
   * @param {Taskable} data - The data to set.
   */
  const update = (data) => {
    helpers.assign(
      _this,
      helpers.dataConverter(
        data,
        helpers.pick(
          {
            max_choices: (value) => Number(value) || 1,
            min_choices: (value) => Number(value) || 1,
            options: (value) =>
              new Array(4).fill({}).map((__, i) => ({
                id: value[i]?.id ?? helpers.getUniqueId(),
                content: value[i]?.content ?? "",
                image: value[i]?.image ?? "",
                order: value[i]?.order ?? i + 1,
                is_correct: value[i]?.is_correct ?? false,
              })),
          },
          keysMap[data.taskable_type],
        ),
      ),
    );
  };

  /**
   * Gets the data for the taskable.
   * @param {Array<keyof Taskable>} keys - The keys to pick from the taskable object.
   * @returns {Taskable|Object} The selected data from the taskable object.
   */
  const value = (keys = []) => {
    if (keys.length > 0) {
      return helpers.pick(_this, keys);
    }
    return _this;
  };

  update(
    helpers.pick(
      data,
      ["id", "taskable_type", "order"].concat(keysMap[data.taskable_type]),
    ),
  );

  return { value, update };
};
