import * as enums from "@/enums";
import dayjs from "@/libs/dayjs";
import * as helpers from "@/libs/helpers";

import * as TaskableModel from "./taskable";

/**
 * Represents a task object.
 * @typedef {Object} Task
 * @property {string} id - The ID of the task.
 * @property {string} title - The title of the task.
 * @property {string} description - The description of the task.
 * @property {string} image - The image of the task.
 * @property {number} coin - The coin of the task.
 * @property {number} exp - The exp of the task.
 * @property {number} limit - The limit of the task.
 * @property {string|null} start_at - The start date of the task.
 * @property {string|null} expired_at - The expiration date of the task.
 * @property {string} status - The status of the task.
 * @property {number|null} order - The order of the task.
 * @property {string} brand_id - The brand ID of the task.
 * @property {Array} taskables - The taskables for the task.
 */

/**
 * Converts the data for a task.
 */
export const converter = {
  toServer: function (data) {
    return helpers.pick(data, [
      "type",
      "name",
      "description",
      "content",
      "image",
      "coin",
      "exp",
      "limit",
      "is_unlimit",
      "start_at",
      "expired_at",
      "closed_at",
      "status",
      "order",
      "brand_id",
    ]);
  },
  fromServer: function (data) {
    return helpers.omit(
      {
        ...data,
        coin: data.coin?.[0]?.value ?? 0,
        exp: data.exp?.[0]?.value ?? 0,
        taskables:
          data.taskables
            ?.filter?.(({ taskable }) => !!taskable)
            ?.map?.(({ taskable_type, taskable_id, order }, i) => {
              const taskable = data[
                `${enums.taskable.taskableTypeMap[taskable_type]}s`
              ]?.find?.((item) => item.id === taskable_id);

              if (!taskable) return null;

              taskable.description = taskable.question;
              taskable.order = order ?? i + 1;
              taskable.taskable_type =
                enums.taskable.taskableTypeMap[taskable_type];

              return TaskableModel.converter(taskable.taskable_type).fromServer(
                taskable,
              );
            }) ?? [],
      },
      ["videos", "clicks", "questions", "surveys"],
    );
  },
};

export const getEmptyData = () => ({
  id: "",
  type: "",
  name: "",
  description: "",
  content: "",
  image: "",
  coin: 0,
  exp: 0,
  limit: 0,
  is_unlimit: false,
  start_at: null,
  expired_at: null,
  closed_at: null,
  status: enums.common.status.ENABLED,
  order: 0,
  brand_id: "",
  taskables: [],
});

/**
 * Creates a task object.
 * @param {Task} data - The initial data for the task.
 */
export const taskFactory = (data) => {
  /**
   * @type {Task}
   */
  const _this = {};

  /**
   * Sets the data for the task.
   * @param {Task} data - The data to set.
   */
  const update = (data) => {
    helpers.assign(
      _this,
      helpers.dataConverter(data, {
        start_at: (value) => (value ? dayjs(value).format() : null),
        closed_at: (value) => (value ? dayjs(value).format() : null),
        expired_at: (value) => (value ? dayjs(value).format() : null),
        coin: (value) => Number(value) || 0,
        exp: (value) => Number(value) || 0,
        limit: (value) => Number(value) || 0,
        is_unlimit: (value) => !!value,
        brand_id: () => data.brands?.[0]?.id || "",
        taskables: (value) =>
          value.map((item) => TaskableModel.factory(item).value()),
      }),
    );
  };

  /**
   * Gets the data for the task.
   * @param {Array<keyof Task>} keys - The keys to pick from the task object.
   * @returns {Task|Object}
   */
  const value = (keys = []) => {
    if (keys.length > 0) {
      return helpers.pick(_this, keys);
    }
    return _this;
  };

  update(data);

  return { value, update };
};
