<script setup>
import { ElButton, ElCard, ElMessageBox, ElNotification } from "element-plus";
import { computed, nextTick, onMounted, ref } from "vue";

import * as APIs from "@/APIs";
import TaskableForm from "@/components/Task/TaskableForm.vue";
import TaskablePreview from "@/components/Task/TaskablePreview.vue";
import TaskPreview from "@/components/Task/TaskPreview.vue";
import * as enums from "@/enums";
import EventEmitter from "@/libs/event-emitter";
import * as utils from "@/libs/utils";
import * as TaskableModel from "@/models/taskable";
import { useTaskStore } from "@/stores/task";

const props = defineProps({
  id: String,
});

const taskStore = useTaskStore();
const taskableFormRefs = ref({});
const initialized = ref(false);
const list = ref([]);

const publishStatus = computed(() =>
  utils.convertTaskPublishStatus(taskStore.task),
);

const editable = computed(() =>
  [enums.task.publishStatus.DRAFT, enums.task.publishStatus.PENDING].includes(
    publishStatus.value,
  ),
);

const init = (taskables) => {
  if (initialized.value) return;
  list.value = taskables.map((item) => ({
    data: item,
    editable: false,
    loading: false,
  }));
  initialized.value = true;
};

const toggleEditable = (index, status) => {
  const item = list.value[index];

  // 新增的題目，編輯狀態下取消編輯，直接刪除
  if (!item.data.id) {
    list.value.splice(index, 1);
    return;
  }

  item.editable = status;
  if (status) {
    nextTick(() => {
      taskableFormRefs.value[index].initFormData(item.data);
    });
  }
};

const submit = async (index) => {
  const taskableData = list.value[index];
  const form = taskableFormRefs.value[index];
  const created = !!taskableData.data.id;
  try {
    taskableData.loading = true;
    await form.validate().then((formData) => {
      taskableData.data = formData;
    });
    if (created) {
      await APIs.taskable.updateTaskable(
        taskableData.data.taskable_type,
        taskableData.data.id,
        taskableData.data,
      );
    } else {
      await APIs.taskable
        .createTaskable(taskableData.data.taskable_type, taskableData.data)
        .then((res) => {
          taskableData.data.id = res.result.id;
        });
      await APIs.task.assignTaskables(
        props.id,
        list.value.map((item, i) => ({
          id: item.data.id,
          type: `${item.data.taskable_type}s`,
          order: item.order ?? i + 1,
        })),
      );
    }
    ElNotification({
      title: "更新成功",
      type: "success",
    });
    taskableData.editable = false;
  } finally {
    taskableData.loading = false;
  }
};

const deleteTaskable = (index) => {
  const item = list.value[index];
  ElMessageBox.confirm(`確定要刪除「${item.data.title}」嗎？`, "提示", {
    type: "warning",
  }).then(async () => {
    try {
      item.loading = true;
      await APIs.task.unbindTaskables(
        props.id,
        item.data.taskable_type,
        item.data.id,
      );
      list.value.splice(index, 1);
      ElNotification({ title: "刪除成功", type: "success" });
    } finally {
      item.loading = false;
    }
  });
};

EventEmitter.on(enums.emitter.events.TASK_FETCH_SUCCESS, (data) =>
  init(data.taskables),
);

onMounted(() => {
  if (taskStore.task.taskables.length) {
    init(taskStore.task.taskables);
  }
});
</script>

<template>
  <div class="max-w-screen-lg">
    <button
      @click="
        $router.push({
          name: enums.route.names.TASK_INFO,
          params: { id },
        })
      "
      class="my-5 flex items-center gap-1"
    >
      <span class="material-symbols-outlined">arrow_back</span>
      <span>返回</span>
    </button>
    <ElCard
      v-for="(item, i) in list"
      :key="item.data.id"
      v-loading="item.loading"
      class="mb-5"
    >
      <div v-if="editable" class="mb-5 flex justify-end">
        <template v-if="item.editable">
          <ElButton type="info" @click="toggleEditable(i, false)">
            取消
          </ElButton>
          <ElButton type="success" @click="submit(i)">更新</ElButton>
        </template>
        <template v-else>
          <ElButton type="primary" @click="toggleEditable(i, true)">
            編輯
          </ElButton>
          <ElButton type="danger" @click="deleteTaskable(i)">刪除</ElButton>
        </template>
      </div>
      <TaskableForm
        v-if="item.editable"
        :ref="(el) => (taskableFormRefs[i] = el)"
        :taskable-type-changeable="!item.data.id"
        :available-taskable-types="
          Object.entries({
            [enums.taskable.taskableTypes.SURVEY]: true,
            [enums.taskable.taskableTypes.QUESTION]: true,
            [enums.taskable.taskableTypes.VIDEO]: true,
            [enums.taskable.taskableTypes.CLICK]: list.length <= 1,
          })
            .filter(([type, available]) => available)
            .map(([type]) => type)
        "
      />
      <TaskPreview
        v-else-if="
          item.data.taskable_type === enums.taskable.taskableTypes.CLICK
        "
        :data="{ ...taskStore.task, url: item.data.url }"
      />
      <TaskablePreview v-else :data="item.data" />
    </ElCard>
    <ElButton
      v-if="
        editable &&
        list.every((item) => !!item.data.id) && // 新增的題目，編輯狀態下取消編輯，直接刪除
        list.filter(item => item.data.taskable_type === enums.taskable.taskableTypes.CLICK).length < 1 // 限制只能新增一個導連任務
      "
      type="success"
      plain
      class="!block"
      :class="{
        'mx-auto': list.length > 0,
      }"
      @click="
        () => {
          list.push({
            data: TaskableModel.factory(
              TaskableModel.getEmptyData(enums.taskable.taskableTypes.SURVEY),
            ),
            editable: true,
            loading: false,
          });
        }
      "
    >
      ＋新增題目
    </ElButton>
  </div>
</template>

<style lang="scss" scoped></style>
