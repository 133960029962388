<script setup>
import { ElMessageBox, ElSwitch } from "element-plus";
import { h } from "vue";

import * as constants from "@/constants";
import * as enums from "@/enums";

const props = defineProps({
  modelValue: [Number, Boolean],
  promptOptions: Object,
  promptAsyncCallback: Function,
  disabled: Boolean,
});

defineEmits(["update:modelValue", "change"]);

const beforeUpdateStatus = (status) => {
  if (props.promptOptions?.disabled) return Promise.resolve(true);
  const enabled = status === enums.common.status.ENABLED;
  return new Promise((resolve) => {
    ElMessageBox.confirm(
      h("p", null, [
        h("span", null, "確定要"),
        h(
          "span",
          enabled
            ? { style: "color: var(--color-danger)" }
            : { style: "color: var(--color-success)" },
          enabled ? "停用" : "啟用",
        ),
        props.promptOptions?.targetName
          ? h("span", null, [
              h("span", null, "《"),
              h(
                "span",
                { style: "font-weight: bold" },
                props.promptOptions.targetName,
              ),
              h("span", null, "》"),
            ])
          : null,
        h("span", null, "嗎？"),
      ]),
      {
        title: props.promptOptions?.title || "注意",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "修改中...";
            await props
              .promptAsyncCallback(
                enabled
                  ? enums.common.status.DISABLED
                  : enums.common.status.ENABLED,
              )
              .catch(() => (instance.action = "cancel"));
            instance.confirmButtonLoading = false;
          }
          done();
        },
      },
    )
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });
};
</script>

<template>
  <ElSwitch
    :model-value="modelValue"
    :active-text="constants.common.statusLabels[enums.common.status.ENABLED]"
    :inactive-text="constants.common.statusLabels[enums.common.status.DISABLED]"
    :active-value="enums.common.status.ENABLED"
    :inactive-value="enums.common.status.DISABLED"
    :style="{
      '--el-switch-on-color': 'var(--color-success)',
      '--el-switch-off-color': 'var(--color-info)',
    }"
    inline-prompt
    :disabled="disabled"
    :before-change="() => beforeUpdateStatus(modelValue)"
    @change="
      (val) => {
        $emit('update:modelValue', val);
        $emit('change', val);
      }
    "
  />
</template>

<style lang="scss" scoped></style>
