function logIfDevelopment(logFunction) {
  return (message, ...optionalParams) => {
    if (import.meta.env.DEV) {
      logFunction(message, ...optionalParams);
    }
  };
}

export const devConsole = {
  log: logIfDevelopment(console.log),
  error: logIfDevelopment(console.error),
  warn: logIfDevelopment(console.warn),
  info: logIfDevelopment(console.info),
  debug: logIfDevelopment(console.debug),
  table: logIfDevelopment(console.table),
};

/**
 * Returns a new object containing only the properties of the given object that are listed in the 'keys' parameter.
 * @param {object} data - An object to pick properties from.
 * @param {string[]} keys - An array of property names to pick from the 'data' object.
 * @returns {object} A new object containing only the properties of the given object that are listed in the 'keys' parameter.
 * @example
 * const data = { a: 1, b: 2, c: 3 };
 * const result = pick(data, ['a', 'c']); // { a: 1, c: 3 }
 */
export function pick(data, keys) {
  const result = {};
  keys.forEach((key) => {
    if (key in data) result[key] = data[key];
  });
  return result;
}

/**
 * create a new array of a given length by randomly selecting elements from a source array
 * @param {array} source - An array to pick elements from.
 * @param {number} [length] - The length of the new array.
 */
export function getRandomPicks(source, length = 1) {
  if (length > source.length) {
    throw new Error("Length cannot be greater than array length");
  }
  const copiedSource = [...source];
  const pickedArray = new Array(length);
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * (copiedSource.length - i));
    pickedArray[i] = copiedSource[randomIndex];
    copiedSource[randomIndex] = copiedSource[copiedSource.length - i - 1];
  }
  return pickedArray;
}

/**
 * Returns a new object with all the properties of the input object except the specified keys.
 * @param {object} data - The object to be processed.
 * @param {string[]} keys - The keys to be omitted from the object.
 * @returns {object} A new object with all the properties of the input object except the specified keys.
 * @example
 * const data = { a: 1, b: 2, c: 3 };
 * const result = omit(data, ['b', 'c']); // { a: 1 }
 */
export function omit(data, keys) {
  const result = { ...data };
  keys.forEach((key) => {
    delete result[key];
  });
  return result;
}

/**
 * Returns a random image from https://picsum.photos
 * @param {object} params
 * @param {number} params.width
 * @param {number} [params.height] - defaults to width
 * @param {boolean} [params.grayscale]
 * @param {number} [params.blur] - between 1 and 10
 * @param {number} [params.random] - random seed
 * @param {"jpg"|"png"|"webp"|"svg"} [params.ext] - image format
 */
export const loremImage = (params) => {
  let queryStr = new URLSearchParams(
    omit(params, ["width", "height", "ext"]),
  ).toString();
  if (queryStr) queryStr = "?" + queryStr;
  let paramsStr = `${params.width}`;
  if (params.height) paramsStr += `/${params.height}`;
  if (params.ext) paramsStr += `.${params.ext}`;
  return `https://picsum.photos/${paramsStr}${queryStr}`;
};

/**
 * @param {object} searchParams
 * @returns {Array<[string, string, string]>} - an array of arrays containing the search params
 */
export function convertSearchParams(searchParams) {
  const convertedParams = [];

  Object.entries(searchParams).forEach(([key, val]) => {
    if (val !== undefined && val !== "") {
      if (Array.isArray(val) && val.length === 2) {
        if (val[0] !== null && val[0] !== undefined)
          convertedParams.push([key, ">=", `${val[0]}`]);
        if (val[1] !== null && val[1] !== undefined)
          convertedParams.push([key, "<=", `${val[1]}`]);
      } else if (/^%.*%$/.test(val)) {
        convertedParams.push([key, "LIKE", `${val}`]);
      } else {
        convertedParams.push([key, "=", `${val}`]);
      }
    }
  });

  return convertedParams;
}

/** Generate Unique ID */
export function getUniqueId() {
  return Math.random().toString(36).substring(2, 9);
}

/**
 * get random integer between min and max (inclusive)
 * @param {number} min minimum value
 * @param {number} max maximum value
 * @returns {number}
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Convert object to FormData if it is not already a FormData
 * @param {object} data
 */
export const convertToFormData = (data) => {
  if (data instanceof FormData) return data;
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

/**
 * Copy text to clipboard
 * @param {string | HTMLElement} text
 */
export const copyToClipboard = async (text) => {
  if (text instanceof HTMLElement) {
    const htmlDecode = (text) => {
      const doc = new DOMParser().parseFromString(text, "text/html");
      return doc.documentElement.textContent || "";
    };
    text = htmlDecode(text.innerHTML);
  }
  return await navigator.clipboard.writeText(text);
};

/**
 * @param {object} target
 * @param {object} sources
 * @returns {object}
 */
export function assign(target, sources) {
  for (const key in sources) {
    target[key] = sources[key];
  }
  return target;
}

/**
 * @param {object} data
 * @param {object} converters
 * @returns {object}
 */
export function dataConverter(data, converters) {
  const convertedData = { ...data };
  for (const key in converters) {
    if (converters[key] !== undefined) {
      convertedData[key] =
        typeof converters[key] === "function"
          ? converters[key]?.(convertedData[key])
          : converters[key];
    }
  }
  return convertedData;
}

/**
 * Returns the contrasting color (either black or white) based on the input color.
 * If the input color is already a valid color string, it returns the input color itself.
 * @param {string} color - The input color string. It can be in hex, rgb, or rgba format.
 * @returns An object with two methods: `toHex` and `toRgb`.
 *          - `toHex` returns the contrasting color as a hex string.
 *          - `toRgb` returns the contrasting color as an rgb string.
 */
export const getContrastingColor = (color) => {
  let rgbColor = [0, 0, 0];

  const hexToRgb = (hex) => {
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
  };

  const rgbToYiq = (rgb) => {
    const [r, g, b] = rgb;
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  if (color.startsWith("#")) {
    const hexColor = color.substring(1);
    rgbColor = hexToRgb(hexColor);
  } else if (color.startsWith("rgb")) {
    rgbColor = color.match(/\d+/g).map(Number);
  }

  const yiq = rgbToYiq(rgbColor);

  return {
    toHex: () => (yiq >= 186 ? "#000000" : "#FFFFFF"),
    toRgb: () => (yiq >= 186 ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)"),
  };
};
