<script setup>
import { computed, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

import Breadcrumb from "@/components/Breadcrumb.vue";
import * as enums from "@/enums";
import EventEmitter from "@/libs/event-emitter";
import { useTaskStore } from "@/stores/task";

const props = defineProps({
  typeId: String,
  id: String,
});

const taskStore = useTaskStore();
const loading = ref(false);
const createMode = computed(() => props.id === enums.common.CREATE_MODE);

if (!createMode.value) {
  loading.value = true;
  taskStore.fetchData(props.id).finally(() => {
    loading.value = false;
  });
} else {
  taskStore.reset({
    type: props.typeId,
  });
}

onBeforeRouteLeave((to, from, next) => {
  EventEmitter.off(enums.emitter.events.TASK_FETCH_START);
  EventEmitter.off(enums.emitter.events.TASK_FETCH_SUCCESS);
  next();
});
</script>

<template>
  <div>
    <Breadcrumb
      :breadcrumbs="[
        {
          label: `${$route.meta.title}列表`,
          to: { name: enums.route.names.TASKS },
        },
        { label: createMode ? `${$route.meta.title}` : taskStore.task.name },
      ]"
    />
    <h1 class="mb-3 text-2xl font-bold">
      {{ createMode ? "新增" : $route.meta.title }}
    </h1>
    <RouterView v-loading="loading" />
  </div>
</template>

<style lang="scss" scoped></style>
